<template>
  <div class="c-app"> 
    <CWrapper v-if="raffle">
      <TheHeader :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <CRow>
              <CCol sm="2">
              </CCol>
              <CCol sm="8">
                <CRow class="privacidad-section-one">
                  <CCol class="terms-titles text-center" sm="12" :style='"color: "+raffle.color_2'>
                    <h3>INFORMACIÓN GENERAL</h3>
                  </CCol>
                  <CCol sm="12">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut sagittis nulla. 
                    Donec tincidunt, odio nec dignissim vehicula, nisi mauris placerat elit, nec 
                    condimentum risus lorem vel turpis. Maecenas ut enim ac erat laoreet malesuada. 
                    Phasellus fringilla magna nec nibh commodo, nec cursus velit tincidunt. Quisque 
                    non ligula eleifend, dictum eros eget, eleifend tortor.
                  </CCol>
                </CRow>
                <CRow class="privacidad-section-two">
                  <CCol class="terms-titles text-center" sm="12" :style='"color: "+raffle.color_2'>
                    <h3>AVISO DE PRIVACIDAD PARA PROSPECTOS</h3>
                  </CCol>
                  <CCol sm="12">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut sagittis nulla. 
                    Donec tincidunt, odio nec dignissim vehicula, nisi mauris placerat elit, nec 
                    condimentum risus lorem vel turpis. Maecenas ut enim ac erat laoreet malesuada. 
                    Phasellus fringilla magna nec nibh commodo, nec cursus velit tincidunt. Quisque 
                    non ligula eleifend, dictum eros eget, eleifend tortor.

                    Sed nec sapien vitae lectus feugiat eleifend. Vestibulum ante ipsum primis in 
                    faucibus orci luctus et ultrices posuere cubilia curae; Nullam dignissim dapibus 
                    nunc, non malesuada arcu ultrices sed. Integer id vehicula mauris. Morbi in 
                    mauris vitae eros convallis consectetur. Mauris a risus eget dui vestibulum 
                    faucibus.

                    Vivamus fermentum nibh non sem faucibus interdum. Curabitur nec magna eu nibh 
                    hendrerit finibus id eget odio. Sed sed ligula eleifend, consequat dolor vel, 
                    lacinia nisl. Nullam sit amet diam nec eros blandit tincidunt. Ut varius, mi eget 
                    tempor luctus, quam dolor tristique tortor, in finibus velit nulla a nisi. Ut 
                    faucibus suscipit nisi at tempor. Integer sed volutpat nisi, et posuere ipsum. 
                    Phasellus ultricies purus at sapien vulputate fermentum. Duis tincidunt sit amet 
                    diam id auctor. Nam congue dolor non augue tincidunt, quis ultricies justo 
                    convallis. Integer laoreet lectus a libero feugiat, eget lacinia arcu auctor. 
                    Quisque bibendum urna felis, at finibus enim commodo vitae. Pellentesque habitant 
                    morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin at 
                    vehicula metus, id interdum turpis.
                  </CCol>
                </CRow>
                <CRow class="privacidad-section-three">
                  <CCol class="terms-titles text-center" sm="12" :style='"color: "+raffle.color_2'>
                    <h3>AVISO DE PRIVACIDAD PARA PARTICIPANTES</h3>
                  </CCol>
                  <CCol sm="12">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut sagittis nulla. 
                    Donec tincidunt, odio nec dignissim vehicula, nisi mauris placerat elit, nec 
                    condimentum risus lorem vel turpis. Maecenas ut enim ac erat laoreet malesuada. 
                    Phasellus fringilla magna nec nibh commodo, nec cursus velit tincidunt. Quisque 
                    non ligula eleifend, dictum eros eget, eleifend tortor.

                    Sed nec sapien vitae lectus feugiat eleifend. Vestibulum ante ipsum primis in 
                    faucibus orci luctus et ultrices posuere cubilia curae; Nullam dignissim dapibus 
                    nunc, non malesuada arcu ultrices sed. Integer id vehicula mauris. Morbi in 
                    mauris vitae eros convallis consectetur. Mauris a risus eget dui vestibulum 
                    faucibus.

                    Vivamus fermentum nibh non sem faucibus interdum. Curabitur nec magna eu nibh 
                    hendrerit finibus id eget odio. Sed sed ligula eleifend, consequat dolor vel, 
                    lacinia nisl. Nullam sit amet diam nec eros blandit tincidunt. Ut varius, mi eget 
                    tempor luctus, quam dolor tristique tortor, in finibus velit nulla a nisi. Ut 
                    faucibus suscipit nisi at tempor. Integer sed volutpat nisi, et posuere ipsum. 
                    Phasellus ultricies purus at sapien vulputate fermentum. Duis tincidunt sit amet 
                    diam id auctor. Nam congue dolor non augue tincidunt, quis ultricies justo 
                    convallis. Integer laoreet lectus a libero feugiat, eget lacinia arcu auctor. 
                    Quisque bibendum urna felis, at finibus enim commodo vitae. Pellentesque habitant 
                    morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin at 
                    vehicula metus, id interdum turpis.

                    Praesent a odio eget lacus mattis interdum eget a mauris. Nulla dapibus, justo in 
                    suscipit tincidunt, ligula tortor mollis neque, ac vehicula felis metus nec odio. 
                    Donec sit amet dolor eu sapien fermentum commodo. Nulla facilisi. Sed ut nulla 
                    volutpat, cursus leo in, vehicula dolor. Ut tincidunt eros nec libero scelerisque, 
                    sit amet consectetur sapien rutrum. Mauris venenatis sapien sit amet malesuada 
                    porttitor. Donec sagittis velit ut tellus suscipit, a mollis eros venenatis. 
                    Qu
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CContainer>
        </main>
      </div>
      <TheFooter :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
  </div>
</template>

<script>
import TheHeader from '../../containers/TheHeaderLanding'
import TheFooter from '../../containers/TheFooterLanding'
import store from '../../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/raffles';

export default {
  name: 'LandingTerms',
  components: {
    TheHeader,
    TheFooter,
    Loading
  },
  data() {
      return {
          has_raffles: true,
          no_raffles_label: "",
          raffle: false,
          color_1: false,
          color_2: false
      }
  },
  mounted: async function() {
    this.loading();

      this.code = await this.$route.params.code;

      let response = false;
      if(this.code === undefined){
        response = await ws.getMainRaffle();  
      }
      else{
        response = await ws.getRaffle(this.code);  
      }

      if(response.type == "success"){
          this.raffle = response.data;
      }
      else{
          this.no_raffles_label = response.message;
          this.has_raffles = false;
      }
    this.loaded();
  }, 
  methods: {
    anchor(anchor_id){
      let anchor = false;

      switch(anchor_id){
        case 'nosotros': case 'preguntas': case 'contacto': case 'garantia': case 'inicio': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/', query: {anchor: anchor_id}});
          return;
        }
        case 'tickets': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/boletos'});
          return;
        }
        case 'payments': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/pagos'});
          return;
        }
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  }  
}
</script>
<style scoped>
.terms-titles{
  font-weight: bolder;
  margin-top: 20px;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.row{
  margin:0px;
}
</style>
